/**
 * AVOID global and cascading styles; ONLY ADD CLASSES UNDER SPECIAL CIRCUMSTANCES
 * after those circumstances have been discussed with team.
 */

html {
  @apply h-full antialiased scroll-smooth;
}

body {
  -webkit-tap-highlight-color: transparent;
  @apply font-sans antialiased text-gray-700 flex flex-col min-h-screen bg-white text-base font-medium;
}

/* This is NextJS' root element */
#__next {
  @apply flex flex-col min-h-screen bg-white;
}

strong,
b {
  @apply font-bold;
}
