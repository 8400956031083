/* DO NOT ADD custom styles to this file */
@import '../../node_modules/@fortawesome/fontawesome-svg-core/styles.css';

@import '../../node_modules/swiper/swiper.min.css';
@import '../../node_modules/swiper/modules/navigation/navigation.min.css';

@import 'tailwindcss/base';
@import './base.css';

@import 'tailwindcss/components';
/* @import "./components.css"; */

@import 'tailwindcss/utilities';
@import './utilities.css';
