/**
 * ONLY ADD CLASSES UNDER SPECIAL CIRCUMSTANCES
 * after those circumstances have been discussed with team
 */

/**
 * Positions custom chevon on select, this was a
 * compromise to building a full on custom select
 * dropdown component
 */
select:not(select--multiple) {
  background-position: right 1rem center;
  background-repeat: no-repeat;
  background-size: 1em 1em;
  &::-ms-expand {
    display: none;
  }
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path fill='%2342484d' d='M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z'></path></svg>");
  @apply h-3.5 w-3.5 cursor-pointer;
}

mark {
  @apply bg-secondary-200;
}

/**
 * Google Recaptcha - Hide badge
 */
.grecaptcha-badge {
  visibility: hidden;
}

/* I don't always nest containers, but when I do, I don't add extra padding */
.container .container {
  @apply px-0;
}

.caption > p {
  @apply inline;
}

/* sets whether lines of text are laid out horizontally or vertically - lr means vertical */
.text-vertical-lr {
  writing-mode: vertical-lr;
}

/* 0verriding rich text stylings for alert bar */
.alert-bar-rich-text {
  & a {
    @apply text-gray-700 hover:text-gray-700;
  }
}

/* 0verriding rich text stylings for directory boards */
.rich-text-xl {
  & p {
    @apply text-[42px] leading-[56px];
  }

  & h1 {
    @apply text-[60px] font-semibold leading-[80px];
  }

  & h2 {
    @apply text-6xl font-semibold;
  }

  & h3 {
    @apply text-[36px] font-medium leading-11;
  }

  & ul,
  ol {
    @apply ml-15;
  }

  & strong {
    @apply font-semibold;
  }
}

.scl-rich-text {
  h1, h2, h3 {
    color: var(--headingTextColor);
  }
  .embedded-entry {
    margin-top: 2rem;
  }
}

a span, .breadcrumb a{
  color: var(--linksColor);
}

.feathery-form {
  [type="text"]:focus, input:where(:not([type])):focus, [type="email"]:focus, [type="url"]:focus, 
  [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, 
  [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, 
  [multiple]:focus, textarea:focus, select:focus {
    --tw-ring-color: var(--feathery-field-border);
    border-color: var(--feathery-field-border);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }

  span.css-1ftsm5b {
    color: #ba4534;
  }
}

:root {
  --primary50: #ebf6f1;
  --primary700: #2C907C;
  --primary800: #017D67;
  --primaryDEFAULT: #2C907C;
  --secondary50: #f7f6f8;
  --secondary200: #fed99b;
  --secondary700: #796d9c;
  --secondary800: #615780;
  --secondaryDEFAULT: #796d9c;
  --headingTextColor: #796d9c;
  --linksColor: #18808C;
  --feathery-field-border: #18808C;
}

:root[data-theme="providers"] {
  --primary50: #f7f6f8;
  --primary700: #796d9c;
  --primary800: #615780;
  --primaryDEFAULT: #796d9c;
  --secondary50: #796d9c;
  --secondary200: #fed99b;
  --secondary700: #796d9c;
  --secondary800: #615780;
  --secondaryDEFAULT: #796d9c;
  --headingTextColor: #42484d;
  --linksColor: #18808C;
  --feathery-field-border: #796d9c;
}

